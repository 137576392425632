<template>
  <v-app id="ppt">

<v-card class="overflow-hidden">
      <v-app-bar
        absolute
        color="#000"
        elevate-on-scroll
        scroll-target="#scrolling-techniques-7"
      >
        <v-toolbar-title>
          <v-img src="./assets/lion_top.png"></v-img>
          
        </v-toolbar-title>
  
        <v-spacer></v-spacer>
  <div>
  <a href="tel:7197559494">
       <!-- <v-hover v-slot="{ hover }">  
        <v-card
          :elevation="hover ? 16 : 2"
          :class="{ 'on-hover': hover }"
          class="mx-auto"         
        >          -->
        <v-img 
        src="./assets/lion_pn.png" alt="(719)755-9494" max-width="238" class="mr-4" ></v-img>
        <!-- </v-card>
       </v-hover> -->
  </a>
  </div>
      </v-app-bar>
      <v-sheet
        id="scrolling-techniques-7"
        class="overflow-y-auto"
        max-height="100vh"
      >
        <v-container>
          <v-row>
            <v-col cols="12" class="d-flex justify-center pb-0">
              <h1 class="mt-5 pt-5">Need a ride? Schedule a pick-up!</h1>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12">
              Welcome to Mountain Lion Limo ~ <b>A Town Car Service for your in-state, county to county, long-distance travel needs.</b> We provide four-wheel drive, full size SUVs. We are a state licensed and commercially insured transportation business. We specialize in airport transportation from The Pikes Peak Region and the Royal George area, through the Spanish Peaks, Wet Mountains and beyond.We are happy to serve you.
            </v-col>
          </v-row>

          <v-row>
          <v-col cols="12" lg="6" md="6" sm="12" class="d-flex-column">
            <!-- {{`${salve}`}} -->
            <!-- <div v-html="`${salve}`"></div> -->
<div><b>Book your ride with us!</b> We will pick you up on time and in style to destinations such as: <b>The Colorado Springs Airport, Pueblo Airport, Denver International Airport</b> and more. Give us a call for quotes and scheduling.</div>
<div class="my-5">We welcome airline accounts for transportation of passengers and flight crews to and from the Colorado Springs Airport.</div>
<div class="my-5">Contact us at: <b><a href="tel:7197559494">(719) 755-9494</a></b> or send an email with your name, pick up date, time, and pick up and drop off location to: <a href="mailto:mountainlionlimo@gmail.com">mountainlionlimo@gmail.com</a>.  We will respond as soon as possible with quotes and availability.</div>
<!---
<div class="my-5"><ul>
  <li>Full refund for cancellation 48 hours prior to pick up.</li>
<li>50% refund for cancellation 24 hours prior to pick up.</li>
<li>On the day of pick up there is no refund.</li>
<li>Upon scheduling, rides must be paid in full.</li>
<li>For airport pick up and drop offs, If there is a plane delay on arrival, there is a $20/HR surcharge for waiting time. If your inbound flight is cancelled, we can reschedule your pick-up.</li>
</ul>
</div>
--->

          </v-col>
          <v-col cols="12" lg="6" md="6" sm="12" class="d-flex justify-center align-top child-flex">
            <!-- child-flex -->
            
<v-img
              :src="require('./assets/d_web.jpg')"
              class="my-1 rounded center"
              contain
              max-width="900"
              
            />
          </v-col>
</v-row>

        <v-row>
          <v-col cols="12" class="my-3 py-3">
          <v-spacer></v-spacer>
          </v-col>
        </v-row>
          
        </v-container>
      </v-sheet>


      <v-footer color="#000" absolute class="font-weight-medium">
      <v-col
          class="text-center"
          cols="12"
        >
          <strong style="color: #fff">Mountain Lion Limo - <a href="tel:7197559494">719-755-9494</a></strong>
        </v-col>
      </v-footer>
          </v-card>
  </v-app>
</template>

<script>
export default {
  name: "App",

  components: {
  },

  data: () => ({
    
  }),

  methods: {
    test(e) {
      console.log('e', e);
    }
  },
};
</script>

<style>
</style>